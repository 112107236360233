// eslint-disable-next-line max-len
import { format, intervalToDuration, nextThursday, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function Password() {
  const currentDate = new Date();
  let ropeDate = currentDate.getDay() === 4 ? currentDate : nextThursday(currentDate);
  ropeDate = setHours(ropeDate, 20);
  ropeDate = setMinutes(ropeDate, 0);
  ropeDate = setSeconds(ropeDate, 0);
  ropeDate = setMilliseconds(ropeDate, 0);
  console.log('ropeDate', ropeDate);

  // const ropeDate = new Date(2023, 7, 10, 20);
  const [diff, setDiff] = useState(intervalToDuration({ start: currentDate, end: ropeDate }));

  useEffect(() => {
    const interval = setInterval(() => {
        setDiff(intervalToDuration({ start: currentDate, end: ropeDate }));
    }, 1000);
    return () => {
        clearInterval(interval);
    }
  });

  return (
    <Wrapper>
        <div>
            AIKAA ROPE SESSION ALKUUN ({format(ropeDate, 'dd.MM.yyyy')} klo {format(ropeDate, 'HH:mm')})
        </div>
        <TimerWrapper>
        <CountBlock>
            <div>Päivää</div>
            <div>{diff.days}</div>
        </CountBlock>
        <CountBlock>
            <div>Tuntia</div>
            <div>{diff.hours}</div>
        </CountBlock>
        <CountBlock>
            <div>Minuuttia</div>
            <div>{diff.minutes}</div>
        </CountBlock>
        <CountBlock>
            <div>Sekuntia</div>
            <div>{diff.seconds}</div>
        </CountBlock>
        </TimerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const TimerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const CountBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

export default Password;
