import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

const canvasHeight = 400;
const canvasWidth = 800;

function CombinationsGraph() {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    // 👇️ use a ref (best)
    const canvas = ref.current;
    console.log(canvas);
    if (!canvas) return;
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.lineWidth = 1;

    // Y
    ctx.beginPath();
    ctx.moveTo(70, 0);
    ctx.lineTo(70, canvasHeight);
    ctx.stroke();

    // X
    ctx.beginPath();
    ctx.moveTo(60, canvasHeight - 10);
    ctx.lineTo(canvasWidth, canvasHeight - 10);
    ctx.stroke();

    ctx.moveTo(0, 0);
    ctx.font = '10px Arial';
    ctx.textAlign = 'end';
    Array.from(Array(10))
      .map((_, i) => Math.pow(10, i + 1))
      .reverse()
      .forEach((combinations, i) => {
        ctx.fillText(String(combinations), 60, 40 * i + 1 + 10);
      });
  }, []);
  return <Wrapper ref={ref}></Wrapper>;
}

const Wrapper = styled.canvas`
  /* border: solid 1px #4d4d4d;
  border-radius: 4px; */
`;

export default CombinationsGraph;
