import React, { useState } from 'react';
import styled from 'styled-components';

import CombinationsGraph from './CombinationsGraph';
import { lowercase, uppercase, special, numbers, benchPassword, tryAllPasswords } from './utils';

function Password() {
  const [password, setPassword] = useState('');
  const [benchMarkCount, setBenchMarkCount] = useState<number>();
  const [benchMarkMs, setbenchMarkMs] = useState<number>();
  const [displayPWDetails, setDisplayPWDetails] = useState(false);
  const passwordArray = password.split('');
  const hasLetter = (array: string[]) => array.some((l) => passwordArray.includes(l));
  const handleStart = () => {
    const a = Date.now();
    setBenchMarkCount(tryAllPasswords());
    setbenchMarkMs(Date.now() - a);
  };

  const handlePasswordSet = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e?.currentTarget?.value);
    setDisplayPWDetails(true);
  };

  const triesPerSecond =
    benchMarkCount && benchMarkMs
      ? Math.floor((benchMarkCount || 1) / (benchMarkMs || 1)) * 1000
      : 1;
  const alphabet = [
    ...(hasLetter(lowercase) ? lowercase : []),
    ...(hasLetter(uppercase) ? uppercase : []),
    ...(hasLetter(numbers) ? numbers : []),
    ...(hasLetter(special) ? special : []),
  ];
  const combinations = Math.pow(alphabet.length, password.length);

  return (
    <Wrapper>
      <Title>Visualisation of relative password strength</Title>

      <PasswordDetails>
        Click on the button first to run a benchmark on your machine to give an estimate of
        potential tries per second.
      </PasswordDetails>

      <button onClick={handleStart}>Benchmark laptop with 4 letters - {benchPassword}</button>

      {benchMarkCount && (
        <div>
          <PasswordDetails>
            <b>Combinations tried:</b> {benchMarkCount}
          </PasswordDetails>
          <PasswordDetails>
            <b>Time lapsed:</b> {benchMarkMs}
          </PasswordDetails>
          <PasswordDetails>
            <b>Estimated tries per second:</b> {triesPerSecond}
          </PasswordDetails>

          <PasswordDetails>Now enter a fake password to evaluate against</PasswordDetails>
          <PasswordInput value={password} onChange={handlePasswordSet} />
        </div>
      )}

      {displayPWDetails && (
        <div>
          <PasswordDetails>Your password strength:</PasswordDetails>
          <PasswordDetails>
            <b>Password:</b> {password}
          </PasswordDetails>
          <PasswordDetails>
            <b>Password length:</b> {password.length}
          </PasswordDetails>
          <PasswordDetails>
            <b>Password complexity:</b>
            <div>{hasLetter(lowercase) ? 'lowercase' : null}</div>
            <div>{hasLetter(uppercase) ? 'uppercase' : null}</div>
            <div>{hasLetter(numbers) ? 'numbers' : null}</div>
            <div>{hasLetter(special) ? 'symbols' : null}</div>
          </PasswordDetails>

          <PasswordDetails>
            There are {combinations} combinations of the letters in the alphabets in your password
          </PasswordDetails>

          <PasswordDetails>
            It would take {Math.floor(combinations / triesPerSecond)} seconds,{' '}
            {Math.floor(combinations / (triesPerSecond * 60))} minutes,{' '}
            {Math.floor(combinations / (triesPerSecond * 60 * 60))} hours,{' '}
            {Math.floor(combinations / (triesPerSecond * 60 * 60 * 24))} days or{' '}
            {Math.floor(combinations / (triesPerSecond * 60 * 60 * 24 * 365))} years to try all the
            combinations
          </PasswordDetails>

          <Paragraph>
            The length of the password together with the length of the alphabet used in the password
            has a dramatic difference in the possible combinations of the password. This directly
            means also how easy the password would be to guess.
          </Paragraph>

          <Paragraph>
            Besides random guessing it is possible to use different kinds of strategies for guessing
            the password. One very efficient way would be trying out the common passwords first,
            then trying some word list attack. Lets say for example we wanted to try Finnish
            alphabet. The language contains around hundred thousant words. Trying them all in their
            basic format would take a few milliseconds. Trying all the combinations of two words
            joined would mean some 10 billion combinations which would be around one second. Using
            some easy to guess password scheme like replacing certain letters with numbers (leet)
            would not help much since the vocabulary would pretty much just double. It is much safer
            to rely on password managers and completely random generated passwords that contain as
            many alphabets as possible and is very long. Using password sentences means that there
            must be a random elements to the password so it cannot be guessed through some list
            attack.
          </Paragraph>

          <Paragraph>
            <CombinationsGraph />
          </Paragraph>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const PasswordInput = styled.input``;
const PasswordDetails = styled.div`
  display: flex;
  gap: 4px;
`;

const Paragraph = styled.div`
  margin: 8px 0px;
`;

export default Password;
